/**
 * user模块接口列表
 */
import createSign from './createSign'; // 导入签名文件
import base from './base'; // 导入接口域名列表
import axios from '../sendMethods.js'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const user = {
    init (params) {
		params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/init`,params);
    },
    //获取绑定手机时的验证码
    getBindPhoneCode(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/bind_phone_sms`, params);
    },
    //绑定手机号
    bindPhone(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/bind_phone`, params);
    },
    //获取换绑手机时验证旧手机号的验证码
    changeBindGetOldPhoneCode(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/modify_bind_phone_validate_sms`, params);
    },
    //校验旧手机号
    verifyOldPhone(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/modify_bind_phone_validate`, params);
    },
    //获取换绑手机时新手机号的验证码
    changeBindGetNewPhoneCode(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/modify_bind_phone_confirm_sms`, params);
    },
    //换绑新手机号
    bindNewPhone(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/modify_bind_phone_confirm`, params);
    },
    //修改密码
    changeAccountPassword(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/modify_password`, params);
    },
    //获取修改密码时的验证码
    getChangeAccountPasswordCode(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/modify_password_sms`, params);
    },
    //设置密码
    setAccountPassword(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/bind_password`, params);
    },
    //获取设置密码时的验证码
    getSetAccountPasswordCode(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/bind_password_sms`, params);
    },
    //获取设置支付密码时的验证码
    getSetPayPasswordCode(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/bind_pay_password_sms`, params);
    },
    //设置支付密码
    setPayPassword(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/bind_pay_password`, params);
    },
    //获取修改支付密码时验证码
    getChangePayPasswordCode(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/modify_pay_password_sms`, params);
    },
    //修改支付密码
    changePayPassword(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/float_user/v1/modify_pay_password`, params);
    },
	//账户注销
	logoff(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/user/v1/account_cancellation`, params);
    },
	//获取注销账号的用户协议
	getLogoffAgreement(params) {
    	params = createSign(params);
        return axios.post(`${base.game}/game/v1/account_cancellation_agreement`, params);
    },
	
}

export default user;