import Vue from 'vue'
import Vuex from 'vuex'

//红包类型icon
import fightingRed from '@/assets/index/fighting_red.svg'
import rechargeRed from '@/assets/index/recharge_red.png'
import otherRed from '@/assets/index/other_red.svg'
import fTriangle from '@/assets/index/f_triangle.svg'
import oTriangle from '@/assets/index/o_triangle.svg'
import rTriangle from '@/assets/index/r_triangle.svg'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        screenOrientation: null, //屏幕方向
        bindPhoneStatus: 0, //手机绑定状态
        platformCoin: 0, //平台币金额
        bindPhone: null, //绑定的手机号
        userAccount: null,
        isSettingAccountPassword: false, //是否设置过账号密码
        hasPayPassword: 0, //是否设置过支付密码,
        serviceType: null, //客服加载类型
        serviceConfig: null, //客服配置参数
        qiyukfUrl: null, //七鱼客服加载地址
        qiyuMsgTotal: 0, //七鱼客服未读消息总数,
        extInfo: null,
        userId: null,
        roleId: null, //平台角色ID
        token: null,
        userName: null,
        tenantCode: null,
        rootDomain: null,
        switchStatusOfRedPacket: {
            RedPacketOfFightingPower: 2, //战力等级红包
            RedPacketOfSign: 2, //签到红包
            RedPacketOfRecharge: 2, //充值红包
            RedPacketOfOther: 2 //其他红包
        }, //红包模块开关状态  1开2关
        signRedPacketInfo: {
            id: null,
            day: null, //n天签到领取红包
            closed_day: null, //领取完毕后多少天关闭
            today_reward_nums: null, //今天奖励个数
            surplus_reward_nums: null, //今天剩余个数
            status: null, //1开2关
            get_days: null //今天应该领取第N数
        },
        cpRoleName: '--',
        userInviteeStatus: 2,
        cpRoleId: null,
        gameRewardMoney: '--',
        gameCoin: '--',
        userEnterDate: null, //用户进入游戏的时间
        currentVersion: "BuyingTraffic" //当前显示内容的版本
    },
    mutations: {
        getScreenOrientation(state, e) {
            switch (e) {
                case 'horizontal':
                    state.screenOrientation = 'Horizontal'
                    break;
                case 'vertical':
                    state.screenOrientation = 'Vertical'
                    break;
                default:
                    state.screenOrientation = e
                    break;
            }
        },
        getBindPhone(state, e) {
            state.bindPhone = e
        },
        getPhoneBindState(state, e) {
            state.bindPhoneStatus = e
        },
        getPlatformCoin(state, e) {
            state.platformCoin = e
        },
        getUserAccount(state, e) {
            state.userAccount = e
        },
        getAccountPasswordSetState(state, e) {
            state.isSettingAccountPassword = e
        },
        getPayPasswordSetState(state, e) {
            state.hasPayPassword = e
        },
        getQiyukfUrl(state, e) {
            state.qiyukfUrl = e
        },
        getQiyuMsgTotal(state, e) {
            state.qiyuMsgTotal = e
        },
        getExtInfo(state, e) {
            state.extInfo = e
        },
        getToken(state, e) {
            state.token = e
        },
        getUserId(state, e) {
            state.userId = e
        },
        getPlatformRoleId(state, e) {
            state.roleId = e
        },
        getUserName(state, e) {
            state.userName = e
        },
        getTenantCode(state, e) {
            state.tenantCode = e
        },
        getRootDomain(state, e) {
            state.rootDomain = e
        },
        getServiceType(state, e) {
            state.serviceType = e
        },
        getServiceConfig(state, e) {
            state.serviceConfig = e
        },
        getServiceHtml(state, e) {
            state.serviceHtml = e
        },
        getServiceUrl(state, e) {
            state.serviceUrl = e
        },
        getSwitchStatusOfRedPacket(state, e) {
            state.switchStatusOfRedPacket = e
        },
        getCpRoleName(state, e) {
            if (e) {
                state.cpRoleName = e
            } else {
                state.cpRoleName = '暂无角色信息'
            }

        },
        getUserInviteeStatus(state, e) {
            state.userInviteeStatus = e
        },
        getCpRoleId(state, e) {
            if (e) {
                state.cpRoleId = e
            } else {
                state.cpRoleId = '暂无角色信息'
            }
        },
        getGameRewardMoney(state, e) {
            state.gameRewardMoney = e
        },
        getGameCoin(state, e) {
            state.gameCoin = e
        },
        getSignRedPacketInfo(state, e) {
            state.signRedPacketInfo = e
        },
        getUserEnterGameDate(state, e) {
            console.log(e)
            state.userEnterDate = e
        },
        getCurrentVersion(state, e) {
            state.currentVersion = e
        },
    },
    getters: {
        getSys: state => state.Sys,
        signRedPacketStatus: state => {
            const dayCount = Number(state.signRedPacketInfo.closed_day) + Number(state.signRedPacketInfo
                .day)
            console.log(state.signRedPacketInfo.status)
            console.log(state.switchStatusOfRedPacket.RedPacketOfSign)
            console.log(state.signRedPacketInfo)
            console.log(dayCount)
            if (state.signRedPacketInfo.role_create_days <= dayCount &&
                state.signRedPacketInfo.status === 1 &&
                state.switchStatusOfRedPacket.RedPacketOfSign === 1) {
                console.log('显示')
                return true
            } else {
                console.log('隐藏')
                return false
            }
        }, //判断是否显示签到红包模块
        isShowTipsOfSignRedPack: state => {
            const packetInfo = state.signRedPacketInfo
            if ((packetInfo.day === packetInfo.role_create_days ||
                    (packetInfo.role_create_days > packetInfo.day &&
                        packetInfo.role_create_days < packetInfo.day + packetInfo.closed_day)
                ) &&
                packetInfo.surplus_reward_nums < 1) {
                console.log('显示活动结束')
                return false
            } else {
                console.log('显示可领')
                return true
            }
        },
        signRedCloseDays: state => {
            const overDays = Number(state.signRedPacketInfo.get_days) - Number(state.signRedPacketInfo.day)
            return Number(state.signRedPacketInfo.closed_day) - overDays
        },
        redModIsNotNull: state => {
            if (state.switchStatusOfRedPacket.RedPacketOfFightingPower === 2 &&
                state.switchStatusOfRedPacket.RedPacketOfSign === 2 &&
                state.switchStatusOfRedPacket.RedPacketOfRecharge === 2 &&
                state.switchStatusOfRedPacket.RedPacketOfOther === 2) {
                return false
            } else {
                return true
            }
        },
        isShowFightingPowerRedPacket: state => {
            if (state.switchStatusOfRedPacket.RedPacketOfFightingPower === 1) {
                return true
            } else {
                return false
            }
        },
        isShowRechargeRedPacket: state => {
            if (state.switchStatusOfRedPacket.RedPacketOfRecharge === 1) {
                return true
            } else {
                return false
            }
        },
        isShowOtherRedPacket: state => {
            if (state.switchStatusOfRedPacket.RedPacketOfOther === 1) {
                return true
            } else {
                return false
            }
        },
        //判断当前整个系统的版本
        currentPageVersion: state => {
            if (state.screenOrientation === 'Horizontal') {
                if (state.currentVersion === "DedicatedServers" ||
                    state.currentVersion === "BuyingTraffic") {
                    return "Horizontal"
                } else if (state.currentVersion === "Discount") {
                    return "Vertical"
                }
            } else if (state.screenOrientation === 'Vertical') {
                return "Vertical"
            }
        }
    },
    actions: {
        async getPlatFormCoinBalance({
            commit,
            state
        }, e) {
            e.$api.pay.getPlatFormCoinBalance({
                user_id: state.userId,
                token: state.token
            }).then(res => {
                if (res.data.code === 200) {
                    commit('getPlatformCoin', (Number(res.data.data.user_coin) / 100).toFixed(2))
                    console.log(state.platformCoin)
                }
            })
        },
        async initForRedPacketMod({
            commit,
            state
        }, e) {
            const {
                that,
                roleId
            } = e,
            requestData = {
                role_id: roleId,
                token: state.token
            };
            that.$api.redPacket.modSwitchState(requestData).then(res => {
                if (res.data.code === 200) {
                    const {
                        cp_role_name,
                        is_invitee,
                        cp_role_id,
                        game_reward_money,
                        game_coin,
                        reward_conf
                    } = res.data.data;
                    let switchOfRedPacketModule = {
                            RedPacketOfFightingPower: 2, //战力等级红包
                            RedPacketOfSign: 2, //签到红包
                            RedPacketOfRecharge: 2, //充值红包
                            RedPacketOfOther: 2 //其他红包
                        }, //红包模块开关状态
                        taskList = []
                    commit('getSwitchStatusOfRedPacket', switchOfRedPacketModule);
                    commit('getCpRoleName', cp_role_name);
                    commit('getUserInviteeStatus', is_invitee); //当前用户是否已经被邀请1否2是
                    commit('getCpRoleId', cp_role_id); //CP角色ID
                    commit('getGameRewardMoney', Number(game_reward_money / 100).toFixed(
                        2)); //角色奖励余额，单位分
                    commit('getGameCoin', game_coin); //游戏币余额，单位元
                    if (that.$route.name !== 'IndexRedPacket') {
                        reward_conf.forEach(item => {
                            if (item.code === 'sign') {
                                switchOfRedPacketModule['RedPacketOfSign'] = item.status
                            }

                        })
                        return
                    }
                    reward_conf.forEach(item => {
                        let redPacketType = ''
                        switch (item.code) {
                            case 'level_power':
                                if (item.status === 1) {
                                    let title = that.redPacketTypeList[0] && that
                                        .redPacketTypeList[0].title ? that
                                        .redPacketTypeList[0].title : ''
                                    taskList.push({
                                        type: 'levelPower',
                                        title: title,
                                        type_icon: fightingRed,
                                        type_icon_s: fTriangle
                                    })
                                }
                                redPacketType = 'RedPacketOfFightingPower'

                                break;
                            case 'sign':
                                redPacketType = 'RedPacketOfSign'
                                break;
                            case 'recharge':
                                if (item.status === 1) {
                                    taskList.push({
                                        type: 'recharge',
                                        title: '充值红包',
                                        type_icon: rechargeRed,
                                        type_icon_s: rTriangle
                                    })
                                }
                                redPacketType = 'RedPacketOfRecharge'

                                break;
                            case 'other':
                                if (item.status === 1) {
                                    taskList.push({
                                        type: 'other',
                                        title: '其他任务',
                                        type_icon: otherRed,
                                        type_icon_s: oTriangle
                                    })
                                }
                                redPacketType = 'RedPacketOfOther'
                                break;
                        }
                        switchOfRedPacketModule[redPacketType] = item.status
                    });

                    that.redPacketTypeList = taskList
                    console.log(that.redPacketTypeList.length)
                    if (taskList.length > 0) {
                        console.log(that.$route.params.pagePosition)
                        if (that.$route.params.pagePosition === 'otherTask' &&
                            switchOfRedPacketModule['RedPacketOfOther'] === 1) {
                            that.choiceRedType({
                                type: 'other',
                                index: that.redPacketTypeList.length - 1
                            })
                        } else {
                            if (!that.currentRedPack) {
                                that.choiceRedType({
                                    type: taskList[0].type,
                                    index: 0
                                })
                            } else {
                                that.choiceRedType({
                                    type: that.currentRedPack,
                                    index: that.currentIndex
                                })
                            }

                        }

                    }
                    that.$common.redirectWhenReload(that)
                }
            })
        },
        async getSignRedPacketInfo({
            commit,
            state
        }, e) {
            const requestData = {
                role_id: state.roleId,
                token: state.token
            };
            let res = await e.$api.redPacket.getSignRedPacketInfo(requestData),
                signRedPacketInfo = {}
            if (res.data.code === 200) {
                const {
                    id, //当前红包的id
                    day, //n天签到领取红包
                    closed_day, //领取完毕后多少天关闭
                    today_reward_nums, //今天奖励个数
                    surplus_reward_nums, //今天剩余个数
                    status, //1开2关
                    get_days, //今天应该领取第N数
                    role_create_days //创角天数
                } = res.data.data
                signRedPacketInfo = {
                    id,
                    day,
                    closed_day,
                    today_reward_nums,
                    surplus_reward_nums,
                    status,
                    get_days,
                    role_create_days
                }
                commit('getSignRedPacketInfo', signRedPacketInfo);
                const dayCount = Number(signRedPacketInfo.closed_day) + Number(signRedPacketInfo.day)

                if (signRedPacketInfo.role_create_days <= dayCount &&
                    signRedPacketInfo.status === 1 &&
                    state.switchStatusOfRedPacket.RedPacketOfSign === 1) {
                    //显示签到红包
                    console.log('显示签到红包')
                    if (signRedPacketInfo.today_reward_nums === signRedPacketInfo.surplus_reward_nums) {
                        //当天还未领取红包,直接储存为可领取的状态
                        console.log('显示签到红包01')
                        const statePack = {
                            enterDate: e.$common.timeFunc().timeStampS,
                            state: true
                        }
                        commit('getUserEnterGameDate', statePack)
                        e.updateUserSignRedPacketState()
                    } else {
                        //当天有领取过红包，判断是否需要开启倒计时
                        console.log('显示签到红包02')
                        console.log(state)
                        console.log(typeof(state.userEnterDate.state))
                        if (state.userEnterDate.hasOwnProperty('state') && (state.userEnterDate.state ===
                                false || state.userEnterDate.state === "false")) {
                            console.log('显示签到红包03')
                            let countdownStartTime = 60 - (e.$common.timeFunc().timeStampS - state
                                .userEnterDate.enterDate)
                            console.log(e.$common.timeFunc().timeStampS)
                            console.log(state.userEnterDate.enterDate)
                            console.log(e.$common.timeFunc().timeStampS - state
                                .userEnterDate.enterDate)
                            e.redPacketOfSignStartCountdown(countdownStartTime)
                        }
                    }
                }
            }
            // if(!state.userEnterDate.state) {
            //     const countdownStartTime = 60 - (e.$common.timeFunc().timeStampS-state.userEnterDate.enterDate)
            //     e.redPacketOfSignStartCountdown(countdownStartTime)
            // }
        }
    },
    modules: {}
})