import sys from './isSys'
import {getEnvUrl} from '../../pluginJS/getPluginInfo.js'
/**
 * 接口域名的管理
 */
let scheme = getEnvUrl().https?'https://':'http://'
//let scheme = 'https://'
let envUrl = getEnvUrl().host
let BASE_OTHER_URL = '', BASE_USER_URL = '', BASE_GAME_URL = '', BASE_PAY_URL = '',BASE_WX_PUBLIC=''
// if(sys() == 'Android'){
// 	console.log(sys())
// 	// BASE_OTHER_URL = 'http://app.raward.android.hjyfxdev.hjygame.com'  //其他接口根目录
// 	// BASE_OTHER_URL = scheme+ 'app-raward-android' + envUrl //其他接口根目录
// 	BASE_USER_URL = scheme+ 'h5sdk-user' + envUrl //获取用户信息及状态
// 	// BASE_GAME_URL = scheme+ 'sdk-game-h5' + envUrl
// 	BASE_PAY_URL = scheme+ 'h5sdk-charge' + envUrl
// 	// BASE_WX_PUBLIC= 'https://api.weixin.qq.com/sns/'
//  }
// //else if(sys() == 'iOS') {
// // 	WEB_URL = 'http://sdk.web.ios.youx88888.com/#/'
// // 	BASE_GAME_URL = 'http://sdk.game.ios.youx88888.com'
// // 	BASE_USER_URL = 'http://sdk.user.ios.youx88888.com'
// // 	BASE_ORDER_URL = 'http://sdk.pay.ios.youx88888.com'
// // }
// else {
// 	console.log(sys())
// 	// BASE_OTHER_URL = scheme+ 'app-raward-android' + envUrl //其他接口根目录
// 	BASE_USER_URL = scheme+ 'h5sdk-user' + envUrl //获取用户信息及状态
// 	// BASE_GAME_URL = scheme+ 'sdk-game-h5' + envUrl
// 	BASE_PAY_URL = scheme+ 'h5sdk-charge' + envUrl
// 	// BASE_WX_PUBLIC= 'https://api.weixin.qq.com/sns/'
// }
BASE_USER_URL = scheme+ 'h5sdk-user' + envUrl
BASE_PAY_URL = scheme+ 'h5sdk-charge' + envUrl
BASE_GAME_URL = scheme+ 'h5sdk-game' + envUrl
BASE_OTHER_URL = scheme + 'h5sdk-charge-test.huanjuyou.com'
BASE_WX_PUBLIC= 'https://api.weixin.qq.com/sns/'

const base = {
    uc: BASE_USER_URL,
    other: BASE_OTHER_URL,
	game: BASE_GAME_URL,
	pay: BASE_PAY_URL,
	wxPublic: BASE_WX_PUBLIC
    // order: BASE_ORDER_URL,
    // url: BASE_URL,
    // web_url: WEB_URL,
}

export default base;