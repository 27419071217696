/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios';
import router from '../router';
import Vant from 'vant';
import 'vant/lib/index.css';
import { Toast } from 'vant';

//需要请求拦截的url
let noInterceptorsUrl = [
        'get_coin_order_info'
	];

/** 
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */

/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        // 401: 未登录状态，跳转登录页
        case 404:
            toLogin();
            break;
        default:
            console.log(other);   
        }}

// 创建axios实例
var instance = axios.create({ timeout: 1000 * 12 });
// 设置post请求头
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    instance.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
/** 
 * 请求拦截器 
 * 每次请求前，如果存在token则在请求头中携带token 
 */ 
instance.interceptors.request.use( 
    config => {
        let url = config.url;
		console.log(noInterceptorsUrl.indexOf(url.split('/').pop()) ===  -1)
		
       if (noInterceptorsUrl.indexOf(url.split('/').pop()) ===  -1) {
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                overlay:false,
                message: '加载中...',
            });
        }
        return config;
        
    },
    error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(function(res){
	console.log(res.status)
	Toast.clear();
	let url = res.config.url;
    if(res.status === 200){
		if(res.data.code === 200) {
		}else {
			if (noInterceptorsUrl.indexOf(url.split('/').pop()) ===  -1) {
				let errmsg = res.data.msg || '未知错误'
			     Toast(errmsg);
			 }
		}
       return Promise.resolve(res)
    }else{
        return Promise.reject(res)
    }
},function(err){
    Toast.clear();
	Toast('请求错误');
	 
})


export default instance;