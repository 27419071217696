/**
 * 红包模块接口列表
 */
import createSign from './createSign'; // 导入签名文件
import base from './base'; // 导入接口域名列表
import axios from '../sendMethods.js'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const redPacket = {
    fightingCapacityRedList(params) {
		params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/get_level_power_reward_list`,params);
    },
	rechargeRedList(params) {
		params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/get_recharge_reward_list`,params);
    },
    modSwitchState(params) {
		params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/get_reward_page_info`,params);
    },
    getFGRedPacket(params) {
		params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/get_level_power_reward`,params);
    },
    getRechargeRedPacket(params) {
		params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/get_recharge_reward`,params);
    },
    getSignRedPacket(params) {
		params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/get_sign_day_reward`,params);
    },
    getSignRedPacketInfo(params) {
		params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/get_sign_day_reward_info`,params);
    },
    bindInvitationCode(params) {
		params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/bind_invite_code`,params);
    },
    getInvitePageInfo(params) {
		params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/get_invite_user_info`,params);
    },
    //获取邀请链接页面游戏信息
    get_game_info(params) {
		params = createSign(params);
        return axios.post(`${base.uc}/expose/v1/get_game_info`,params);
    },
}

export default redPacket;