import { JSEncrypt } from 'jsencrypt'
import store from '@/store' 
import {timeFunc} from '@/pluginJS/getPluginInfo.js'

// import httpBuildQuery from 'http-build-query'
export default function createSign(param){
	console.log(param)
	let publicKey = '-----BEGIN PUBLIC KEY-----\n' + 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAJoSTxp3DN2fMKRZrPaUro4pFCkCoQpU' + '\n' + 'v0fKqc0/dyn3tkZLA53g8hdyi7DSwhoeq53SAeD2sNbgfTtuFrjdsRsCAwEAAQ==' + '\n-----END PUBLIC KEY-----';
	let httpBuildQuery = require('http-build-query');
	let newData = {};
	let newStr = ''
    param.ext_info = store.state.extInfo
    param.request_time = timeFunc().timeStampS
	Object.keys(param).sort().map(key => {
	  newData[key]=param[key]
	})
	let sign = '';
	newStr = httpBuildQuery(newData);
	let encryptor = new JSEncrypt();
	//console.log(newStr)
	// 设置公钥
	encryptor.setPublicKey(publicKey);
	// 加密数据
	sign = encryptor.encryptLong2(newStr);
    param.sign = sign
	return JSON.stringify(param);
}