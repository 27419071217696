import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { getNowSystem } from "@/pluginJS/getPluginInfo.js";
import { Toast } from "vant";

const Home = () => import("../views/Home.vue");
const Index = () => import("../views/index/index.vue");
const IndexRedPacket = () => import("../views/index/indexRedPacket/index.vue");
const InviteIndex = () => import("../views/index/inviteIndex/index.vue");
const InviteImage = () => import("../views/index/inviteIndex/inviteImage.vue");
const ExchangeIndex = () => import("../views/index/exchange/index.vue");//兑换首页
const ExchangeDetail = () => import("../views/index/exchange/detail.vue");//兑换首页
const Mine = () => import("../views/mine/index.vue");
const MineIndex = () => import("../views/mine/index/index.vue");
const BindPhoneIndex = () => import("../views/mine/bindPhone/index.vue");
const AccountAdminIndex = () => import("../views/mine/accountAdmin/index.vue");
const PayPasswordIndex = () => import("../views/mine/payPassword/index.vue");
const PlatformCoinIndex = () => import("../views/mine/platformCoin/index.vue");
const OrderList = () => import("../views/mine/platformCoin/orderList.vue");
const OrderDetail = () => import("../views/mine/platformCoin/orderDetail.vue");
const GameOrderIndex = () => import("../views/mine/gameOrder/index.vue");
const GameOrderDetail = () => import("../views/mine/gameOrder/orderDetail.vue");
const GameNoticeIndex = () => import("../views/mine/gameNotice/index.vue");
const GameNoticeDetail = () =>
  import("../views/mine/gameNotice/noticeDetail.vue");
const LogoffIndex = () => import("../views/mine/logoffIndex/index.vue");
const LogoffAgreement = () => import("../views/mine/logoffIndex/agreement.vue");
const WelfareIndex = () => import("../views/welfare/index.vue");
const WelfareListIndex = () => import("../views/welfare/welfareList.vue");
const GiftDetail = () => import("../views/welfare/giftDetail.vue");
const WelfareDetail = () => import("../views/welfare/welfareDetail.vue");
const StrategyIndex = () => import("../views/strategy/index.vue");
const StrategyListIndex = () => import("../views/strategy/strategeIndex.vue");
const StrategyDetail = () => import("../views/strategy/strategyDetail.vue");
const CustomerIndex = () => import("../views/customer/index.vue");
const Blank = () => import("../views/singlePage/blank.vue");
const PlatformRechageReturn = () =>
  import("../views/singlePage/platformRechageReturn.vue");
const WebPayPage = () => import("../views/singlePage/webPayPage.vue");
const LandingPage = () => import("../views/singlePage/landingPage.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    name: "Home",
    meta: {
      title: "tenant",
      keepAlive: false
    },
    children: [
      {
        path: "/index",
        component: Index,
        redirect: "/index/indexRedPacket",
        name: "Index",
        meta: {
          title: "",
        },
        children: [
          {
            path: "indexRedPacket",
            component: IndexRedPacket,
            name: "IndexRedPacket",
            meta: {
              title: "首页",
            }
          },
          {
            path: "inviteIndex",
            component: InviteIndex,
            name: "InviteIndex",
            meta: {
              title: "邀请好友",
            }
          },
          {
            path: "inviteImage",
            component: InviteImage,
            name: "InviteImage",
            meta: {
              title: "生成邀请图片",
            }
          },
          {
            path: "exchangeIndex",
            component: ExchangeIndex,
            name: "ExchangeIndex",
            meta: {
              title: "兑换",
            },
          },
          {
            path: "exchangeDetail",
            component: ExchangeDetail,
            name: "ExchangeDetail",
            meta: {
              title: "兑换明细",
            }
          }
        ]
      },
      {
        path: "/mine",
        component: Mine,
        redirect: "/mine/mineIndex",
        name: "Mine",
        meta: {
          title: "我的",
        },
        children: [
          {
            path: "mineIndex",
            component: MineIndex,
            name: "MineIndex",
            meta: {
              title: "我的",
            },
          },
          {
            path: "bindPhoneIndex",
            component: BindPhoneIndex,
            name: "BindPhoneIndex",
            meta: {
              title: "绑定手机",
            },
          },
          {
            path: "accountAdminIndex",
            component: AccountAdminIndex,
            name: "AccountAdminIndex",
            meta: {
              title: "账号管理",
            },
          },
          {
            path: "payPasswordIndex",
            component: PayPasswordIndex,
            name: "PayPasswordIndex",
            meta: {
              title: "支付密码",
            },
          },
          {
            path: "platformCoinIndex",
            component: PlatformCoinIndex,
            name: "PlatformCoinIndex",
            meta: {
              title: "平台币",
            },
          },
          {
            path: "orderList",
            component: OrderList,
            name: "OrderList",
            meta: {
              title: "订单记录",
            },
          },
          {
            path: "orderDetail",
            component: OrderDetail,
            name: "OrderDetail",
            meta: {
              title: "订单详情",
            },
          },
          {
            path: "gameOrderIndex",
            component: GameOrderIndex,
            name: "GameOrderIndex",
            meta: {
              title: "游戏订单",
            },
          },
          {
            path: "gameOrderDetail",
            component: GameOrderDetail,
            name: "GameOrderDetail",
            meta: {
              title: "订单详情",
            },
          },
          {
            path: "gameNoticeIndex",
            component: GameNoticeIndex,
            name: "GameNoticeIndex",
            meta: {
              title: "游戏公告",
            },
          },
          {
            path: "gameNoticeDetail",
            component: GameNoticeDetail,
            name: "GameNoticeDetail",
            meta: {
              title: "公告详情",
            },
          },
          {
            path: "logoffIndex",
            component: LogoffIndex,
            name: "LogoffIndex",
            meta: {
              title: "账号注销",
            },
          },
          {
            path: "logoffAgreement",
            component: LogoffAgreement,
            name: "LogoffAgreement",
            meta: {
              title: "用户注销协议",
            },
          },
        ],
      },
      {
        path: "/welfare",
        component: WelfareIndex,
        name: "WelfareIndex",
        redirect: "/welfare/welfareListIndex",
        meta: {
          title: "福利",
        },
        children: [
          {
            path: "welfareListIndex",
            component: WelfareListIndex,
            name: "WelfareListIndex",
            meta: {
              title: "福利",
            },
          },
          {
            path: "giftDetail",
            component: GiftDetail,
            name: "GiftDetail",
            meta: {
              title: "礼包详情",
            },
          },
          {
            path: "welfareDetail",
            component: WelfareDetail,
            name: "WelfareDetail",
            meta: {
              title: "福利详情",
            },
          },
        ],
      },
      {
        path: "/strategy",
        component: StrategyIndex,
        name: "StrategyIndex",
        redirect: "/strategy/strategyListIndex",
        meta: {
          title: "攻略",
        },
        children: [
          {
            path: "strategyListIndex",
            component: StrategyListIndex,
            name: "StrategyListIndex",
            meta: {
              title: "攻略",
            },
          },
          {
            path: "strategyDetail",
            component: StrategyDetail,
            name: "StrategyDetail",
            meta: {
              title: "攻略详情",
            },
          },
        ],
      },
      {
        path: "/customer",
        component: CustomerIndex,
        name: "CustomerIndex",
        meta: {
          title: "客服",
        },
      },
    ],
  },
  {
    path: "/blank",
    component: Blank,
    name: "Blank",
    meta: {
      title: "blank",
    },
  },
  {
    path: "/platformRechageReturn",
    component: PlatformRechageReturn,
    name: "PlatformRechageReturn",
    meta: {
      title: "支付结果",
    },
  },
  {
    path: "/webPayPage",
    component: WebPayPage,
    name: "WebPayPage",
    meta: {
      title: "支付中",
    },
  },
  {
    path: "/landingPage",
    component: LandingPage,
    name: "LandingPage",
    meta: {
      title: "游戏下载",
    },
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  //window.webkit.messageHandlers.Log.postMessage("2222:-----" + JSON.stringify(to));
  // next();
  console.log(to.query.redirect);
  console.log(to);
  let urlArr = to.fullPath.split("/");
  if (
    !urlArr.includes("blank") &&
    !urlArr.includes("platformRechageReturn") &&
    !urlArr.includes("webPayPage") &&
    !urlArr.includes("landingPage")
  ) {
    // if (to.name !== 'IndexRedPacket') {
    //     //判断是否需要重新获取请求参数
    //     console.log('is not index')
    //     if (store.state.extInfo === null || store.state.token === null || store.state.userId === null) {
    //         console.log(to.path)
    //         Toast('参数获取失败')
    //         next({
    //             path: `/index/indexRedPacket?redirect=${to.path}`
    //         })
    //         return
    //     } else {
    //         next();
    //     }

    // } else {
    //     next();
    // }
    next();
    
  } else {
    if (
      urlArr.includes("platformRechageReturn") &&
      to.name !== "PlatformRechageReturn"
    ) {
      let pArr = urlArr[urlArr.length - 1],
        order_id = pArr.split("?")[0];
      next({
        name: "PlatformRechageReturn",
        params: {
          order_id,
        },
      });
      return;
    } else {
      next();
    }
  }
});

export default router;
