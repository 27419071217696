/**
 * 红包模块接口列表
 */
import createSign from './createSign'; // 导入签名文件
import base from './base'; // 导入接口域名列表
import axios from '../sendMethods.js'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const exchange = {
    //获取可兑换的游戏币选项列表
    getExchangeGameCoinList(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/get_exchange_game_coin_list`,params);
    },
    //兑换游戏币
    exchangeGameCoin(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/exchange_game_coin`,params);
    },
    //获取余额明细
    getBalanceChangeHistoryList(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/get_user_role_balance_change_list`,params);
    },
    //获取游戏币明细
    getCoinChangeHistoryList(params) {
    	params = createSign(params);
        return axios.post(`${base.uc}/reward/v1/get_user_role_game_coin_change_list`,params);
    },
}

export default exchange;