<template>
	<router-view/>
</template>
<script>
	// import VueDisbounce from "./components/disBounce.vue";
	
	export default {
		components: {
			// VueDisbounce
		},
		data() {
			return {
			}
		},
		methods: {
			
		},
		created() {
			document.title = ''
		}
	}
</script>
<style>
	
	* {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
		-webkit-touch-callout: none;
		/*系统默认菜单被禁用*/

		-webkit-user-select: none;
		/*webkit浏览器*/

		-khtml-user-select: none;
		/*早期浏览器*/

		-moz-user-select: none;
		/*火狐*/

		-ms-user-select: none;
		/*IE10*/

		user-select: none;

	}

	/* 但是以上代码也会禁止输入框的使用，可用以下代码解决： */

	input {
		-webkit-user-select: auto;
		/*webkit浏览器*/

	}
	
	textarea {
		/* //如果存在textarea也输入不进文本的情况下，加上这个 */

		-webkit-user-select: auto;
		/*webkit浏览器*/

	}
	
	html,
	body {
		width: 100%;
		height: 100%;
		-webkit-text-size-adjust: none;
	}
	html {
		position: fixed;
	}
	body {
		
		overflow: hidden
	}

	#app {
		width: 100%;
		height: 100%;
	}

	input::-webkit-input-placeholder {
		color: #CCCCCC;
	}

	input::-moz-input-placeholder {
		color: #CCCCCC;
	}

	input::-ms-input-placeholder {
		color: #CCCCCC;
	}

	input:focus {
		outline: none;
	}
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .input-box-v {
        display: flex;
        align-items: center;
        width: 100%;
        height: 42px;
        background: #F5F7F7;
        padding: 0 12px;
        margin-bottom: 10px;
        border-radius: 6px;
        overflow: hidden;
    }
    .input-v {
        flex: 1;
        height: 100%;
        border: none;
        background: #F5F7F7;
    }
    .input-box-h {
        display: flex;
        align-items: center;
        width: 100%;
        height: 26.25px;
        background: #F5F7F7;
        padding: 0 7.5px;
        margin-bottom: 7.5px;
        border-radius: 4px;
        overflow: hidden;
    }
    .input-h {
        flex: 1;
        height: 100%;
        border: none;
        background: #F5F7F7;
        font-size: 7.5px;
    }
	.van-cell {
		border: 1px solid #E4E4E4;
		border-radius: 8px;
	}
	 ::-webkit-scrollbar {
	     width: 0 !important;
	   }
	   ::-webkit-scrollbar {
	     width: 0 !important;
         height: 0;
	   }
    .all-page {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: scroll;
    }
    .all-page-content-box {
        flex: 1;
        overflow: scroll;
    }
   @media screen and (orientation: landscape) {
    	.van-toast {
    	    padding: 0 8px !important;
    	    flex-direction: row !important;
    	    width: auto !important;
    	    min-height: 8.75px !important;
    	}
    	
    	.van-toast__text {
    	    font-size: 8.75px;
    	    margin-top: 0 !important;
    	}
    	.van-loading__spinner {
    	    width: 16px !important;
    	}
    }
    
</style>
