/**
 * pay模块接口列表
 */
import createSign from './createSign'; // 导入签名文件
import base from './base'; // 导入接口域名列表
import axios from '../sendMethods.js'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const game = {
    //获取游戏公告列表
    getGameNoticeList(params) {
        params = createSign(params);
        return axios.post(`${base.game}/float_game/v1/get_game_announcement_list`, params);
    },
    //获取游戏公告详情
    getGameNoticeDetail(params) {
        params = createSign(params);
        return axios.post(`${base.game}/float_game/v1/get_game_announcement_detail`, params);
    },
    //获取礼包分类列表
    getGiftTypeList(params) {
        params = createSign(params);
        return axios.post(`${base.game}/float_game/v1/get_game_gift_cat_list`, params);
    },
    //获取礼包列表
    getGiftList(params) {
        params = createSign(params);
        return axios.post(`${base.game}/float_game/v1/get_game_gift_list`, params);
    },
    //领取礼包
    getGiftPack(params) {
        params = createSign(params);
        return axios.post(`${base.game}/float_game/v1/get_game_gift_receive`, params);
    },
    //获取礼包详情
    getGiftDetail(params) {
        params = createSign(params);
        return axios.post(`${base.game}/float_game/v1/get_game_gift_detail`, params);
    },
    //获取攻略列表
    getStrategyList(params) {

        params = createSign(params);
        return axios.post(`${base.game}/float_game/v1/get_game_strategy_list`, params);
    },
    //获取攻略详情
    getStrategyDetail(params) {
        params = createSign(params);
        return axios.post(`${base.game}/float_game/v1/get_game_strategy_detail`, params);
    },
}

export default game;
