import {
    Toast
} from "vant";
import store from "../store";
import router from "../router";

export function getUrlKey(name, url) {
    return (
        (new RegExp("[?|&|#]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(url) || [,
            "",
        ])[1].replace(/\+/g, "%20") || null
    );
}
//复制文字
export function copyText(text, msg, type) {
    if (text) {
        var domUrl = document.createElement("input");
        domUrl.value = text;
        domUrl.id = "creatDom";
        document.body.appendChild(domUrl);
        domUrl.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        let creatDom = document.getElementById("creatDom");
        creatDom.parentNode.removeChild(creatDom);
        if (type && type === "invite") {
            return true;
        }
        Toast({
            message: "复制成功！",
        });
    } else {
        Toast({
            message: msg + "不存在",
        });
        if (type && type === "invite") {
            return false;
        }
    }
}

export function getSys() {
    console.log(navigator.platform);
    let native;
    let p = navigator.platform;
    let u = navigator.userAgent;
    let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
    let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (
        p == "Win32" ||
        p == "Win64" ||
        p == "MacPPC" ||
        p == "MacIntel" ||
        p == "X11" ||
        p == "Linux i686"
    ) {
        //PC调试环境
        //console.log('PC环境--供PC端调试');
        native = "Android";
    } else {
        if (is_android) {
            //Android终端
            //console.log('Mobile环境--Android移动端');
            native = "Android";
        } else if (is_ios) {
            //IOS终端
            //console.log('Mobile环境--IOS移动端');
            native = "IOS";
        }
    }
    return native;
}
//判断是否是微信浏览器的函数
export function isWeiXin() {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    let ua = window.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}
//判断环境
export function getNowSystem() {
    let sys;

    if (/(gsiOS)/i.test(navigator.userAgent)) {
        sys = "ios";
    } else if (/(gsAndroid)/i.test(navigator.userAgent)) {
        sys = "Android";
    } else {
        sys = "web";
    }
    return sys;
}
//判断当前运行模式
export function getEnvUrl() {
    let env = {
        https: true,
        host: ".huanjuyou.com",
    };
    if (/(-test.)/i.test(window.location.host)) {
        // 测试环境
        console.log("测试环境");
        env.https = false;
        env.host = "-test.huanjuyou.com";
    } else if (/(-pre.)/i.test(window.location.host)) {
        // 预发布环境
        env.https = true;
        env.host = "-pre.huanjuyou.com";
    } else if (/(-dev.)/i.test(window.location.host)) {
        // 开发环境
        env.https = false;
        env.host = "-test.huanjuyou.com";
    } else if (/(192.168)/i.test(window.location.host)) {
        env.https = false;
        env.host = "-pre.huanjuyou.com";
    } else {
        // 生产环境
        env.host = ".huanjuyou.com";
    }
    return env;
}
//调用ios公共方法
export function postMessageToIos(methodsName, param) {
    const methodsListV2 = ["getPlatformRoleId", "getUserEnterGameDate", "getScreenOrientation"] 
    if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers[methodsName]
    ) {
        eval(
            "window.webkit.messageHandlers." +
            methodsName +
            ".postMessage(" +
            param +
            ")"
        );
    }else {
        if(methodsListV2.includes(methodsName)) {
            // 当前为1.0版本
            console.log("当前为1.0版本")
            screenOrientation()
            store.commit("getCurrentVersion", "DedicatedServers");
            store.commit("getPlatformRoleId", "暂无角色信息");
            store.commit("getUserEnterGameDate", "当前版本不支持")
        }
    }
}
export function postMessageToWindowParent(gsFloatEventType, data) {
    console.log(gsFloatEventType);
    if (!window || !window.parent) {
        router.push({
            name: "Blank",
            params: {
                message: null,
            },
        });
        return;
    }
    window.parent.postMessage({
        gsFloatEventType,
        data
    }, "*");
}
export function postMessageToIframe(gsFloatEventType, data) {
    let payReturnIframe = document.getElementById("payReturnIframe")
        .contentWindow;
    if (!payReturnIframe) {
        router.push({
            name: "Blank",
            params: {
                message: null,
            },
        });
        return;
    }
    payReturnIframe.postMessage({
        gsFloatEventType,
        data
    }, "*");
}
export function uploadOrderInfo(orderInfo) {
    if (getNowSystem() === "Android") {
        const {
            game_name,
            order_money,
            order_number
        } = orderInfo;
        if (android.onPaymentSuccess) {
            android.onPaymentSuccess(
                "",
                game_name,
                order_number,
                "",
                "",
                "",
                order_money
            );
        }
    }
}
export function getPayReturnUrl(payType, routePath) {
    let currentEnv = "",
        returnUrl = "";
    if (/(-test.)/i.test(getEnvUrl().host)) {
        currentEnv = "-sdkfloat-test.";
    } else {
        currentEnv = "-sdkfloat.";
    }
    returnUrl =
        window.location.protocol +
        "//" +
        store.state.tenantCode +
        currentEnv +
        store.state.rootDomain +
        "/#/platformRechageReturn";
    return returnUrl;
}

//巨量上报方法
export function subJuLiang(orderInfo, that) {
    let postData = {};
    switch (getNowSystem()) {
        case "ios":
            return;
        case "Android":
            if (
                android.getJuLiangParams &&
                typeof android.getJuLiangParams === "function"
            ) {
                postData = Object.assign(
                    postData,
                    JSON.parse(android.getJuLiangParams()),
                    orderInfo
                );
                that.$api.pay.subForJuLiang(postData).then((res) => {
                    console.log("巨量");
                    console.log(res);
                });
            }

            break;
        default:
            break;
    }
}
export function timeFunc(val) {
    let timeDataPack = {},
        date = null;
    if (val) {
        if (typeof val === "number" && JSON.stringify(val).length === 10) {
            val = val * 1000;
        }
        date = new Date(time);
    } else {
        date = new Date();
    }
    let yy = date.getFullYear(),
        mon =
        date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1),
        day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate(),
        hh = date.getHours() > 9 ? date.getHours() : "0" + date.getHours(),
        mm = date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes(),
        ss = date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds(),
        timeStamp = date.getTime();

    timeDataPack.timeStampS = (Number(timeStamp) / 1000).toFixed(0);
    timeDataPack.timeStampMS = timeStamp;
    timeDataPack.timeToSecond =
        yy + "." + mon + "." + day + " " + hh + ":" + mm + ":" + ss;
    timeDataPack.timeToDay = yy + "." + mon + "." + day;
    return timeDataPack;
}
//隐藏姓名
export function formatName(name) {
    let len = name.length;
    let lastName = name.substring(0, 1);
    for (let i = 0; i < len - 1; i++) {
        lastName = lastName + "*";
    }
    return lastName;
}
//获取屏幕方向
export function screenOrientation() {
    if (window.innerHeight < window.innerWidth) {
        //横
        store.commit("getScreenOrientation", "Horizontal");
    } else {
        //竖
        store.commit("getScreenOrientation", "Vertical");
    }
}
//分页查询
export function pagingRequest(e, that) {
    if (e.offsetHeight + Math.ceil(e.scrollTop) >= e.scrollHeight) {
        console.log(that.dataList.length);
        console.log(that.currentPage * that.limit);
        if (that.dataList.length === that.currentPage * that.limit) {
            //当前不是最后一页
            that.currentPage = that.currentPage + 1;
            that.getList();
        } else {
            Toast({
                message: "没有更多了！",
            });
        }
    }
}
export function logout() {
    switch (getNowSystem()) {
        case "ios":
            postMessageToIos("nativeLogout", null);
            return;
        case "Android":
            android.nativeLogout();
            break;
        default:
            console.log("web");
            postMessageToWindowParent("nativeLogout", null);
            break;
    }
}
export function redirectWhenReload(that) {
    if (that.$route.query.redirect) {
        console.log("that.$route.query.redirect");
        //初始化成功后跳转到指定的页面
        router.push({
            name: that.$route.query.redirect,
        });
    } else {
        router.replace({
            name: "Mine",
        });
        // if (store.state.currentVersion !== "BuyingTraffic") {
        //     router.replace({
        //         name: "Mine",
        //     });
        // } else {
        //     router.replace({
        //         name: "Index",
        //     });
        // }
    }
}
export function verifyPhone(phone) {
    if (phone) {
        if (phone.length !== 11) {
            Toast({
                message: "手机号格式不正确",
            });
            return false;
        }
        return true;
    } else {
        Toast({
            message: "请输入手机号",
        });
        return false;
    }
}
export function verifyPhoneCode(code) {
    if (code) {
        if (code.length !== 6) {
            Toast({
                message: "验证码格式不正确",
            });
            return false;
        }
        return true;
    } else {
        Toast({
            message: "请输入验证码",
        });
        return false;
    }
}
export function afterBindPhone(code) {
    if (!code || code.length !== 11) {
        Toast({
            message: "手机号格式不正确",
        });
        return;
    } else {
        const phoneHead = code.substring(0, 3),
            phoneFoot = code.substring(7, 11),
            encryptedPhone = phoneHead + "****" + phoneFoot;
        store.commit("getBindPhone", encryptedPhone);
        setTimeout(() => {
            store.commit("getPhoneBindState", 1);
        }, 2500);
    }
}