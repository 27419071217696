/** 
 * api接口的统一出口
 */
import user from './user';
import pay from './pay';
import game from './game';
import base from './base'; //url地址
import redPacket from './redPacket';
import exchange from './exchange';
// 其他模块的接口……

// 导出接口
export default {
    user,
    pay,
    game,
    redPacket,
    exchange
}