import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'lib-flexible'
import api from './http/api'
import VueClipboard from 'vue-clipboard2' //vue复制文本功能
import 'default-passive-events'
import vRometeJs from 'v-remote-js' //该组件可用于引入外部js链接
import * as CommonJs from './pluginJS/getPluginInfo.js'


Vue.use(vRometeJs)
Vue.use(VueClipboard)


import {
    Toast
} from 'vant';


Vue.prototype.$mywindow = window
Vue.prototype.$Toast = Toast
Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$store = store
Vue.prototype.$common = CommonJs

Vue.use(Vant);


Vue.filter('getRewardType', function(val) {
    if (val) {
        let RewardType = ''
        switch (val) {
            case 1:
                RewardType = '新人红包'
                break
            case 2:
                RewardType = '等级红包'
                break
            case 3:
                RewardType = '战力红包'
                break
            case 4:
                RewardType = '签到红包'
                break
            case 5:
                RewardType = '邀请好友红包'
                break
            case 6:
                RewardType = '游戏币兑换'
                break
            case 7:
                RewardType = '充值红包'
                break
        }
        return RewardType
    }
})
Vue.filter('gameCoinChangeType', function(val) {
    if (val) {
        let type = ''
        switch (val) {
            case 1:
                type = '游戏支付'
                break
            case 2:
                type = '余额兑换'
                break
            case 3:
                type = '官方发放'
                break
        }
        return type
    }
})
Vue.filter('changTimeType', function(val) {
    if (val) {
        let time = val * 1000
        let date = new Date(time)
        let yy = date.getFullYear()
        let mon = date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
        let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
        let hh = date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
        let mm = date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
        let ss = date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds()
        let itemTime = yy + '.' + mon + '.' + day + ' ' + hh + ':' + mm + ':' + ss
        return itemTime
    }
})
Vue.filter('changDayType', function(val) {
    if (val) {
        let time = val * 1000
        let date = new Date(time)
        let yy = date.getFullYear()
        let mon = date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
        let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
        let itemTime = yy + '.' + mon + '.' + day
        return itemTime
    }
})
Vue.filter('changIntervalTime', function(val) {
    if (val) {
        let time = Math.ceil(val / 86400000)
        return time
    }
})
Vue.filter('changMoney', function(val) {
    if (val || val === 0) {
        let money = '0.00'
        money = (Number(val) / 100).toFixed(2)
        return money
    }
})
Vue.filter('formatName', function(val) {
    if (val) {
        let len = val.length
        let lastName = val.substring(0, 1)
        let firstName = val.substring(len - 1, len)
        let changName = ''
        if (len > 2) {
            changName = lastName + '*' + firstName
        } else if (len === 2) {
            changName = lastName + '*'
        } else {
            changName = lastName
        }
        return changName
    }
})
Vue.filter('formatFightingCapacity', function(val) {
    if (val || val === 0) {
        let valed = val.toString();
        let numLen = valed.length
        let head = valed.substring(0, 1)
        let endNum = 0 //最终输出结果
        if (numLen > 4) {
            //战力大于一万
            console.log(numLen)
            switch (valed.length) {
                case 5:
                    endNum = head + '万';
                    break;
                case 6:
                    if (head > 1) {
                        endNum = head + '十万';
                    } else {
                        endNum = '十万';
                    }

                    break;
                case 7:
                    endNum = head + '百万';
                    break;
                case 8:
                    endNum = head + '千万';
                    break;
                case 9:
                    endNum = head + '亿';
                    break;
                case 10:
                    endNum = head + '十亿';
                    break;
                case 11:
                    endNum = head + '百亿';
                    break;
                case 12:
                    endNum = head + '千亿';
                    break;
                case 13:
                    endNum = head + '万亿';
                    break;
                case 14:
                    if (head > 1) {
                        endNum = head + '十万亿';
                    } else {
                        endNum = '十万亿';
                    }

                    break;
                case 15:
                    endNum = head + '百万亿';
                    break;
                case 16:
                    endNum = head + '千万亿';
                    break;
            }

        } else {

            endNum = val


        }
        return endNum
    }
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')