/**
 * pay模块接口列表
 */
import createSign from './createSign'; // 导入签名文件
import base from './base'; // 导入接口域名列表
import axios from '../sendMethods.js'; // 导入http中创建的axios实例
import qs from 'qs'; // 根据需求是否导入qs模块

const pay = {
    //获取平台币余额
    getPlatFormCoinBalance (params) {
		params = createSign(params);
        return axios.post(`${base.pay}/charge/v1/user_coin`, params);
    },
    //创建平台币订单页面
    createOrderForPlatfomCoin(params) {
		params = createSign(params);
        return axios.post(`${base.pay}/float_charge/v1/confirm_coin_order`, params);
    },
    //获取支付通道
    getPayMethods(params) {
		params = createSign(params);
        return axios.post(`${base.pay}/charge/v1/charge_channel`, params);
    },
    //获取支付状态
    getPayState(params) {
        let rd = JSON.parse(JSON.stringify(params))
		rd = createSign(rd);
        return axios.post(`${base.pay}/float_charge/v1/get_coin_order_info`, rd);
    },
    //获取平台币订单列表
    getPlatFormCoinRechargeHistory(params) {
		params = createSign(params);
        return axios.post(`${base.pay}/float_charge/v1/get_coin_order_list`, params);
    },
    //获取平台币订单详情
    getPlatFormCoinRechargeDetail(params) {
    	params = createSign(params);
        return axios.post(`${base.pay}/float_charge/v1/get_coin_order_detail`, params);
    },
    //获取游戏订单列表
    getPlatFormGameOrderHistory(params) {
    	params = createSign(params);
        return axios.post(`${base.pay}/float_charge/v1/get_game_order_list`, params);
    },
    //获取游戏订单详情
    getPlatFormGameOrderDetail(params) {
    	params = createSign(params);
        return axios.post(`${base.pay}/float_charge/v1/get_game_order_detail`, params);
    },
}

export default pay;